@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global CSS */
.bg-grey-f7fafc {
  background-color: #f7fafc;
}

.bg-grey-52627e {
  background-color: #52627e;
}

.bg-grey-e8ebf1 {
  background-color: #e8ebf1;
}

.bg-grey-ebebeb {
  background-color: #ebebeb;
}

.bg-red-c22125 {
  background-color: #c22125;
}

.bg-red-ffefef {
  background-color: #ffefef;
}

.bg-orange-ecac58 {
  background-color: #ecac58;
}

.text-red-c22125 {
  color: #c22125;
}

.text-grey-52627e {
  color: #52627e;
}

.text-gray-385f80 {
  color: #385f80 !important;
}

.text-gray-2a374d {
  color: #2a374d !important;
}

.from-red-cc2323-to-eb9898 {
  background: rgb(204, 35, 35);
  background: linear-gradient(
    90deg,
    rgba(204, 35, 35, 1) 0%,
    rgba(235, 152, 152, 1) 100%
  );
}

.w-508px {
  width: 508px;
}

/* Global CSS */

/* Start Login */
.login-dev-info {
  background-color: #fbfcfd;
  height: 100vh;
  width: 100%;
}

.login-dev-info .container .login-card {
  border-radius: 26px;
  box-shadow: 0 19px 32px 0 rgba(0, 0, 0, 0.06);
}

.login-custom-google {
  color: #fff !important;
  box-shadow: none !important;
  font-size: 1.2rem !important;
  padding-right: 7px !important;
  border-radius: 4px !important;
  border: transparent !important;
  background-color: #4285f4 !important;
}

.login-custom-google div {
  margin: 0 15px 0 2px !important;
  padding: 14px !important;
}

.login-dev-info h3 {
  font-weight: bold !important;
  letter-spacing: -0.3px;
  color: #02335d;
}

.login-dev-info .powered {
  color: #02335d;
}

.login-dev-info .powered img {
  height: 30px;
  width: auto;
}

.login-dev-info h3 .red {
  color: #c22125;
}

/* End Login */

/* Start Division */
.division .item {
  height: 20rem;
  overflow: hidden;
  position: relative;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.division .item:after {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  background: #212121 none repeat scroll 0 0;
}

.division .item img {
  height: 100%;
  top: -9999px;
  margin: auto;
  left: -9999px;
  right: -9999px;
  bottom: -9999px;
  position: absolute;
  width: auto !important;
}

.division .item:hover,
.division .item:focus,
.division .item:active {
  height: 25rem;
}

.division .owl-stage {
  display: flex;
  height: 25rem;
  align-items: center;
}

/* Start Division */

.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}

/* Start Admin */
input:checked + svg {
  display: block;
}

.dashboard-title-card {
  position: relative;
}

.dashboard-title-card:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 9.5rem;
  border-bottom: 1px solid #000;
}

.badge-right {
  position: absolute;
  top: 50%;
  right: -0.75rem;
  transform: translateY(-50%);
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
}

.division-card {
  min-width: 12rem;
  width: auto;
}

/* Modal */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

/* Breadcrumb */
.breadcrumb-item::before {
  display: inline-block;
  content: "";
  mask: url(./assets/icon_chevron_right.svg);
  -webkit-mask: url(./assets/icon_chevron_right.svg);
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  width: 10px;
  height: 10px;
}

/* table */
.table-body tr:nth-child(odd) {
  background-color: #f0f0f0;
}
/* End Admin */

/* Slick custom */
.slick-prev {
  left: -40px;
}

.slick-next:before,
.slick-prev:before {
  color: #bc3236;
  opacity: 1;
  font-size: 34px;
}

.slick-dots {
  bottom: -35px;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li button:before {
  color: #a2acb5;
}

.slick-dots li.slick-active button:before {
  color: #bc3236;
  opacity: 1;
  font-size: 14px;
}

.slick-dots li button:before {
  font-size: 10px;
}

/* Other */
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.status-approval {
  color: #fff;
  border-radius: 12px;
  padding: 3px 8px;
  font-size: small;
}
.status-approve {
  background: rgb(34 197 94);
}

.status-revision {
  background: rgb(59 130 246);
}

.status-reject {
  background: rgb(220 38 38);
}

.status-pending {
  background: rgb(234 179 8);
}

.status-draft {
  background: rgb(100 116 139);
}
/* } */

.preview-modal::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.preview-modal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

/* #react-doc-viewer #file-name::after {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' stroke-width='1.5' stroke='currentColor'><path stroke-linecap='round' stroke-linejoin='round' d='M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3' /></svg>");
    font-size: 12px;
} */
